<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Temporary Vouchers</v-card-title>
        <v-data-table
          :mobile-breakpoint="NaN"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          hide-default-header
          height="75vh"
          :items="voucherList"
          class="tbl-temp-voucher"
        >
          <template v-slot:header="{ headers }">
            <thead>
              <tr>
                <th rowspan="2">VoucherCode</th>
                <th rowspan="2">Date</th>
                <th rowspan="2">ModifiedDate</th>
                <th rowspan="2">To Customer</th>
                <th rowspan="2">For Person</th>
                <th rowspan="2">IssuedBy</th>
                <th colspan="2">Amount</th>
                <th rowspan="2">Remark</th>
                <th rowspan="2">Action</th>
              </tr>
              <tr>
                <th>MMK</th>
                <th>USD</th>
              </tr>
            </thead>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(item, index) in items">
                <tr :key="index">
                  <td>{{ item.voucherCode }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.date }}</td>
                  <td>
                    {{ item.toCustomer.customerName }} -
                    {{ item.toCustomer.phone }}
                  </td>
                  <td>
                    <v-label
                      v-for="(person, index) in item.forPerson"
                      :key="person.name"
                      >{{ index == 0 ? "" : " , "
                      }}{{ person.pass.passTypeName }}
                      {{ person.name }}</v-label
                    >
                  </td>
                  <td>{{ item.issuedBy }}</td>
                  <td>{{ item.mmkTotal }}</td>
                  <td>{{ item.usdTotal }}</td>
                  <td>{{ item.remark }}</td>
                  <td>
                    <v-btn
                      icon
                      color="primary"
                      @click="editVoucher(item.voucherCode)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="deleteVoucher(index)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "VoucherCode",
          value: "voucherCode"
        },
        {
          text: "Date",
          value: "date"
        },
        {
          text: "ModifiedDate",
          value: "modifiedDate"
        },
        {
          text: "To",
          value: "toCustomer"
        },
        {
          text: "For",
          value: "forPerson"
        },
        {
          text: "IssuedBy",
          value: "issuedBy"
        },

        {
          text: "USD Amount",
          value: "usdAmount"
        },
        {
          text: "MMK Amount",
          value: "mmkAmount"
        },
        {
          text: "Remark",
          value: "remark"
        },
        {
          text: "Action",
          value: "action"
        }
      ]
    };
  },
  computed: {
    voucherList: function() {
      return this.$store.getters["TempVoucher/getVoucherList"];
    }
  },
  methods: {
    deleteVoucher: function(index) {
      console.log(index);
      this.$store.commit("TempVoucher/removeVoucher", index);
    },
    editVoucher: function(code) {
      this.$router
        .push({
          name: "voucher",
          params: {
            voucherId: code
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style>
.tbl-temp-voucher table,
.tbl-temp-voucher td,
.tbl-temp-voucher th {
  padding: 4px;
}

.tbl-temp-voucher thead td {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-temp-voucher thead th {
  font-size: 14px;
  padding: 2px;
  height: 24px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-temp-voucher tbody th,
.tbl-temp-voucher tbody td {
  font-size: 13px;
  padding-left: 3px;
  border: 1px solid #e7e7e7;
  height: 30px !important;
  padding-right: 1px;
}
.row-shadow {
  box-shadow: inset 0px 2px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -2px 8px -5px rgba(50, 50, 50, 0.75);
}
</style>
